<template>
  <div id="top">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div style="padding-top:15px;display:inline-block;">
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              <small>{{ tipoProprietario }} id: {{ idServidor }}</small>
            </h5></span
          >
        </div>
        <div class="switch-div" v-show="checkPapel()">
          <md-switch v-model="editionMode">Modo Edição </md-switch>
        </div>
        <div class="md-layout md-gutter md-alignment-center">
          <div class="md-layout-item">
            <h4 class="title" style="font-weight:350;">
              Arquivo :
            </h4>
          </div>
          <div>
            <md-button
              type="button"
              class="md-raised mt-4 md-success"
              @click="addNovaPasta(count++)"
              :disabled="checkEditionDisabled()"
              v-show="checkPapel()"
            >
              Nova Pasta
            </md-button>
          </div>
        </div>
        <div v-for="(pasta, index) in pastas" :key="index">
          <div
            v-if="!isFetching"
            style="padding-top:15px;display:inline-block;"
          ></div>
          <div class="md-layout">
            <div
              class="md-layout-item md-small-size-100 md-size-100 md-medium-size-100 md-xsmall-size-100"
              v-show="pasta.showFieldPasta"
            >
              <ValidationProvider
                :name="'pasta-' + index"
                :rules="{
                  required: true,
                  max: 100,
                  alpha_dash: true
                }"
                v-slot="{ failed, errors }"
              >
                <md-field :class="[{ 'md-error': failed }]">
                  <md-icon>folder</md-icon>

                  <md-input
                    v-model="pasta.nomePasta"
                    type="text"
                    :maxlength="100"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>

                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="col-xs-2" v-show="pasta.showFieldPasta">
              <md-button
                class="md-just-icon md-danger md-simple"
                :disabled="checkEditionDisabled()"
                @click="closeFolder(false, index)"
              >
                <md-icon>close</md-icon>
              </md-button>
              <md-button
                class="md-just-icon md-success md-simple"
                :disabled="checkEditionDisabled()"
                @click="saveNovaPasta(index)"
              >
                <md-icon>done</md-icon>
              </md-button>
            </div>
          </div>
          <div class="md-layout">
            <!-- <div class="grouping">
              <md-button
                style="vertical-align: middle;"
                class="folder md-just-icon md-simple"
                :disabled="checkEditionDisabled()"
                @click="fieldPastaChangeName(true, index)"
              >
                <md-icon>folder</md-icon>
              </md-button>
              <label class="labelInLine">
                {{ pasta.nomePasta }}
              </label>
              <md-button
                v-show="showIconTrashUpdater"
                style="vertical-align: middle;"
                class="md-danger md-just-icon md-simple"
                :disabled="checkEditionDisabled()"
                @click="removePasta(index)"
              >
                <md-icon>delete_forever</md-icon>
              </md-button>
            </div> -->

            <div class="md-layout-item md-small-size-100 md-size-100">
              <div class="list md-size-100">
                <md-list :md-expand-single="expandSingle" class="md-size-100">
                  <md-list-item md-expand>
                    <md-button
                      style="vertical-align: middle;"
                      class="folder md-just-icon md-simple"
                      :disabled="checkEditionDisabled()"
                      @click="fieldPastaChangeName(true, index)"
                    >
                      <md-icon>folder</md-icon>
                    </md-button>
                    <span class=""
                      >{{ pasta.nomePasta }}

                      <md-button
                        v-show="showIconTrashUpdater"
                        style="vertical-align: middle;"
                        class="md-danger md-just-icon md-simple"
                        :disabled="checkEditionDisabled()"
                        @click="removePasta(index)"
                      >
                        <md-icon>delete_forever</md-icon>
                      </md-button>
                    </span>

                    <md-list slot="md-expand">
                      <md-list-item
                        v-for="(item, idx) in pasta.arquivos"
                        :key="idx"
                        class="md-inset"
                      >
                        <md-button
                          class="md-just-icon md-success md-round"
                          v-show="!editionMode"
                          @click="
                            downloadId(
                              item.idArquivo,
                              item.nomeArquivo,
                              item.versao
                            )
                          "
                          ><md-icon>get_app</md-icon></md-button
                        >
                        <div
                          class="box-list"
                          @click="
                            downloadId(
                              item.idArquivo,
                              item.nomeArquivo,
                              item.versao
                            )
                          "
                        >
                          {{ item.nomeArquivo }}
                          {{ " - V" + item.versao }}
                        </div>

                        <div class="grouping">
                          <md-button
                            v-show="showIconTrashUpdater"
                            class="file md-just-icon md-danger md-simple"
                            :disabled="checkEditionDisabled()"
                            @click="removeArquivo(item.idArquivo)"
                          >
                            <md-icon>delete_forever</md-icon>
                          </md-button>
                          <md-button
                            v-show="showIconTrashUpdater"
                            class="file md-just-icon md-success md-simple"
                            :disabled="checkEditionDisabled()"
                            @click="
                              popUpdateVersao(
                                item.idArquivo,
                                item.nomeArquivo,
                                pasta.idPasta,
                                index,
                                item.versao
                              )
                            "
                          >
                            <md-icon>update</md-icon>
                            <md-tooltip md-direction="top"
                              >Versionamento</md-tooltip
                            >
                          </md-button>
                          <md-button
                            v-show="showIconTrashUpdater"
                            class="file md-just-icon md-simple"
                            :disabled="checkEditionDisabled()"
                            @click="
                              popHistoryVersao(
                                item.idArquivo,
                                item.nomeArquivo,
                                pasta.idPasta,
                                index,
                                item.versao
                              )
                            "
                          >
                            <md-icon>history </md-icon>
                            <md-tooltip md-direction="top"
                              >Versões Anteriores</md-tooltip
                            >
                          </md-button>
                        </div>
                      </md-list-item>
                    </md-list>
                  </md-list-item>
                </md-list>
              </div>

              <!--<ul class="nobull">
                <li v-for="(item, idx) in pasta.arquivos" :key="idx">
                  <a
                    href="#"
                    @click="downloadId(item.idArquivo, item.nomeArquivo)"
                    >{{ item.nomeArquivo }} {{ " - V" + item.versao }}</a
                  >
                  <md-button
                    v-show="showIconTrashUpdater"
                    class="file md-just-icon md-danger md-simple"
                    :disabled="checkEditionDisabled()"
                    @click="removeArquivo(item.idArquivo)"
                  >
                    <md-icon>delete_forever</md-icon>
                  </md-button>
                  <md-button
                    v-show="showIconTrashUpdater"
                    class="file md-just-icon md-success md-simple"
                    :disabled="checkEditionDisabled()"
                    @click="downloadId(item.idArquivo, item.nomeArquivo)"
                  >
                    <md-icon>update</md-icon>
                  </md-button>
                </li>
              </ul> -->
              <form
                v-bind:id="index + 100"
                :key="index"
                enctype="multipart/form-data"
                novalidate
                v-if="isInitial(index) || isSaving(index) || isSuccess(index)"
              >
                <div class="dropbox" v-show="showDrag">
                  <input
                    :id="index"
                    type="file"
                    multiple
                    :name="pasta.uploadFieldName"
                    :disabled="isSaving(index)"
                    @change="
                      filesChange(
                        $event.target.name,
                        $event.target.files,
                        pasta.nomePasta,
                        pasta.idPasta,
                        index
                      );
                      fileCount = $event.target.files.length;
                    "
                    accept="*/*"
                    class="input-file"
                  />
                  <p v-if="isInitial(index) || isSuccess(index)">
                    Arraste e Solte seu arquivo(s) aqui <br />
                    ou click para Selecionar
                  </p>
                  <p v-if="isSaving(index)">
                    Enviando {{ fileCount }} arquivos...
                  </p>
                </div>
                <div
                  :id="index"
                  v-if="isSuccess(index) && editionMode"
                  v-show="pastas[index].currentStatus === 2"
                >
                  <p>
                    Enviado
                    {{ pasta.uploadedFiles.totalFiles }} arquivo(s) com sucesso.
                  </p>
                </div>
              </form>
              <!--SUCCESS-->

              <!--FAILED
              <div v-if="isFailed(index)">
                <h2>Uploaded failed.</h2>
                <p>
                  <a href="javascript:void(0)" @click="reset()">Try again</a>
                </p>
                <pre>{{ uploadError }}</pre>
              </div>-->
            </div>
          </div>
        </div>
        <br />
      </form>
    </ValidationObserver>
    <template>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">Versões do Arquivo<br /></h4>

          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>
        <template slot="body">
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-100">
              <div class="list-modal">
                <md-list :md-expand-single="expandSingle">
                  <md-list-item md-expand>
                    <md-button
                      style="vertical-align: middle;"
                      class="folder md-just-icon md-simple"
                      :disabled="checkEditionDisabled()"
                    >
                      <md-icon>text_snippet</md-icon>
                    </md-button>
                    <span class="">{{ modalNomeArquivo }} </span>

                    <md-list slot="md-expand">
                      <md-list-item
                        v-for="(item, idx) in versoesArquivos"
                        :key="idx"
                        class="md-inset"
                        ><div
                          class="box-list"
                          @click="
                            downloadId(
                              item.idArquivo,
                              item.nomeArquivo,
                              item.versao
                            )
                          "
                        >
                          {{ item.nomeArquivo }}
                          {{ " - V" + item.versao }}
                        </div>
                        <div class="grouping">
                          <md-button
                            class="file md-just-icon md-danger md-simple"
                            :disabled="checkEditionDisabled()"
                            @click="
                              downloadId(
                                item.idArquivo,
                                item.nomeArquivo,
                                item.versao
                              )
                            "
                          >
                            <md-icon>get_app</md-icon>
                          </md-button>
                        </div>
                      </md-list-item>
                    </md-list>
                  </md-list-item>
                </md-list>
              </div>
            </div>
          </div>
        </template>
        <template slot="footer"></template>
      </modal>
    </template>
  </div>
</template>

<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { Modal } from "@/components";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import {
  upload,
  download,
  uploadNovaVersao,
  instance
} from "../js/file-upload.download.service";
import * as axios from "axios";
import scrollToTopPage from "../js/scrollToTopPage";
import { getLocalToken } from "@/modules/auth/storage";

const axiosInstance = instance;

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  props: {
    id: {
      type: String
    },
    isEdition: {
      type: Boolean
    },
    tipoProprietario: { type: String }
  },
  components: {
    Modal
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("servidor_publico", {
      servidorPublicoSelected: "servidorPublicoState"
    }),
    ...mapGetters("pensionista", {
      pensionistaSelected: "pensionistaState"
    }),
    ...mapGetters("arquivo", {
      arquivoState: "arquivoState",
      pastaState: "pastaState"
    }),
    user_profile_state: state => state.user_profile
  },
  beforeMount() {
    this.getPapel();
    const token = getLocalToken();
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  data() {
    return {
      fileCount: 0,
      expandNews: false,
      expandSingle: false,
      count: 0,
      idServidor: this.id,
      idPensionista: this.id,
      arquivosFromServ: [],
      arquivosFromPen: [],
      editionMode: false,
      isDisabledEdition: true,
      isFetching: true,
      papel: "",
      arquivo: {
        idArquivo: "",
        nomeArquivo: "",
        versao: ""
      },
      pasta: {
        idPasta: "",
        nomePasta: "raiz",
        nomePastaAnterior: "",
        arquivos: [],
        showFieldPasta: false,
        uploadFieldName: "files",
        currentStatus: STATUS_INITIAL,
        uploadedFiles: {}
      },
      pastas: [],
      arquivos: [],
      uploadedFiles: [],
      showIconTrashUpdater: false,
      classicModal: false,
      modalNomeArquivo: "",
      versoesArquivos: [],
      showButton: false
    };
  },
  mounted() {
    if (this.tipoProprietario === "SERVIDOR") {
      this.getArquivoFromServ().then(() => {
        this.loadServidor();
      });
    }
    if (this.tipoProprietario === "PENSIONISTA") {
      this.getArquivoFromPen().then(() => {
        this.loadPensionista();
      });
    }
  },
  methods: {
    ...mapActions("arquivo", [
      "ActionSetARQUIVO",
      "ActionSetPASTA",
      "ActionGetArquivoFromServ",
      "ActionGetArquivoFromPen",
      "ActionPOSTArquivo",
      "ActionDELETEArquivo",
      "ActionPUTArquivo",
      "ActionDELETEPasta",
      "ActionPUTPasta",
      "ActionGetArquivoVersoesById"
    ]),
    checkPapel() {
      return this.papel === "ADMIN" ? true : false;
    },
    isInitial: function(index) {
      if (
        typeof this.pastas[index] !== "undefined" &&
        this.pastas[index] !== null &&
        this.pastas.length &&
        this.pastas.length > 0 &&
        // eslint-disable-next-line prettier/prettier
        (typeof(this.pastas[index].currentStatus) !== "undefined" && this.pastas[index].currentStatus !== null   )    
      ) {
        return this.pastas[index].currentStatus === STATUS_INITIAL;
      } else {
        return true;
      }
      return true;
    },
    isSaving: function(index) {
      if (
        typeof this.pastas[index] !== "undefined" &&
        this.pastas[index] !== null &&
        this.pastas.length &&
        this.pastas.length > 0 &&
        typeof this.pastas[index].currentStatus !== "undefined" &&
        this.pastas[index].currentStatus !== null
      ) {
        return this.pastas[index].currentStatus === STATUS_SAVING;
      } else {
        return false;
      }
    },
    isSuccess: function(index) {
      if (
        typeof this.pastas[index] !== "undefined" &&
        this.pastas[index] !== null &&
        this.pastas.length &&
        this.pastas.length > 0 &&
        typeof this.pastas[index].currentStatus !== "undefined" &&
        this.pastas[index].currentStatus !== null
      ) {
        return this.pastas[index].currentStatus === STATUS_SUCCESS;
      } else {
        return false;
      }
    },
    isFailed: function(index) {
      if (
        typeof this.pastas[index] !== "undefined" &&
        this.pastas[index] !== null &&
        this.pastas.length &&
        this.pastas.length > 0 &&
        typeof this.pastas[index].currentStatus !== "undefined" &&
        this.pastas[index].currentStatus !== null
      ) {
        return this.pastas[index].currentStatus === STATUS_FAILED;
      } else {
        return false;
      }
    },
    downloadId(id, nome, versao) {
      download(axiosInstance, id, nome, versao, this.papel);
    },
    callScrollToTopPage: function() {
      scrollToTopPage();
    },
    popHistoryVersao(idArquivo, nomeArquivo, idPasta, index, versao) {
      this.getArquivoVersoesById(idArquivo).then(() => {
        this.classicModal = true;
        this.modalNomeArquivo = nomeArquivo;
      });
    },
    async popUpdateVersao(idArquivo, nomeArquivo, idPasta, index, versao) {
      const { value: file } = await Swal.fire({
        title: `Selecione um Arquivo para versão \nV-${versao + 1}`,
        text: ` \n arquivo\n ${nomeArquivo}`,
        input: "file",
        inputAttributes: {
          accept: "*/*",
          "aria-label": "Upload your profile picture"
        }
      });
      if (file) {
        if (nomeArquivo === file.name) {
          this.uploadVersao(
            "files",
            file,
            idArquivo,
            nomeArquivo,
            idPasta,
            index,
            versao
          );
        } else {
          Swal.fire({
            title: `Erro no Versioniamento.`,
            icon: "error",
            text: "Os nomes dos arquivos devem ser identicos.",
            customClass: {
              confirmButton: "md-button md-raised md-danger btn-fill"
            },
            buttonsStyling: false
          });
        }
      }
    },
    addNovaPasta: function(index) {
      this.pasta.nomePasta = "raiz-" + index;
      this.pastas.push(Vue.util.extend({}, this.pasta));
    },
    saveNovaPasta: function(index) {
      if (this.pastas[index].idPasta === "") {
        this.ActionSetPASTA(this.pastas);
        this.fieldPastaChangeName(false, index);
      } else {
        if (this.$refs.observer.errors) {
          let checkerror = Object.keys(this.$refs.observer.errors).some(key => {
            let checkerrorArray =
              this.$refs.observer.errors[key].length > 0 ? true : false;
            return checkerrorArray;
          });
          if (!checkerror) {
            this.submit(index);
            this.fieldPastaChangeName(false, index);
          }
        }
      }
    },
    emitToParent(value) {
      this.$emit("edition", value);
    },
    closeFolder(value, index) {
      if (!value) {
        this.pastas[index].nomePasta = this.pastas[index].nomePastaAnterior;
        this.pastas[index].showFieldPasta = value;
      }
    },
    fieldPastaChangeName(value, index) {
      if (index == null && value == false) {
        this.pastas.map(p => {
          p.showFieldPasta = value;
        });
      } else {
        this.pastas[index].nomePastaAnterior = this.pastas[index].nomePasta;
        this.pastas[index].showFieldPasta = value;
      }
    },
    removePasta: function(index) {
      if (this.pastas[index].idPasta === "") {
        this.fieldPastaChangeName(false, index);
        Vue.delete(this.pastas, index);
      } else {
        Swal.fire({
          title: "Você tem certeza ?",
          text: `Se você excluir a Pasta todos arquivos serão excluídos juntos! Isto será irreversível !`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir !",
          cancelButtonText: "CANCELAR",
          customClass: {
            confirmButton: "md-button md-success md-raised mt-4",
            cancelButton: "md-button md-danger md-raised mt-4"
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.deletePasta(this.pastas[index].idPasta, index);
          }
        });
      }
    },
    async deletePasta(idPasta, index) {
      try {
        let id = idPasta;
        let papel = this.papel;
        let response = await this.ActionDELETEPasta({ id, papel });
        if (response.status === 200) {
          this.mensagem = response.body.mensagem;
          if (
            this.mensagem.includes("Falha ao tentar excluir a Pasta") ||
            this.mensagem.includes("não")
          ) {
            Swal.fire({
              title: `Pasta: Não Excluida.`,
              icon: "error",
              text: this.mensagem,
              text: `${this.mensagem}`,
              customClass: {
                confirmButton: "md-button md-raised md-danger btn-fill"
              },
              buttonsStyling: false
            });
          } else {
            Swal.fire({
              icon: "success",
              title: `Pasta: Excluida!`,
              text: `${this.mensagem}`,
              customClass: {
                confirmButton: "md-button md-raised mt-4 md-success btn-fill"
              },
              buttonsStyling: false
            });
            this.fieldPastaChangeName(false, index);
            this.reload(); //@focusout="focusOnFolder(false, index)"
          }
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    removeArquivo: function(idArquivo) {
      Swal.fire({
        title: "Você tem certeza ?",
        text: `Se você excluir este Arquivo será irreversível!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir !",
        cancelButtonText: "CANCELAR",
        customClass: {
          confirmButton: "md-button md-success md-raised mt-4",
          cancelButton: "md-button md-danger md-raised mt-4"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteArquivo(idArquivo);
        }
      });
    },
    async deleteArquivo(idArquivo) {
      try {
        let id = idArquivo;
        let papel = this.papel;
        let response = await this.ActionDELETEArquivo({ id, papel });
        if (response.status === 200) {
          this.mensagem = response.body.mensagem;
          if (this.mensagem.includes("não")) {
            Swal.fire({
              title: `Arquivo: Não Excluido.`,
              icon: "error",
              text: this.mensagem,
              text: `${this.mensagem}`,
              customClass: {
                confirmButton: "md-button md-raised md-danger btn-fill"
              },
              buttonsStyling: false
            });
          } else {
            Swal.fire({
              icon: "success",
              title: `Arquivo: Excluido!`,
              text: `${this.mensagem}`,
              customClass: {
                confirmButton: "md-button md-raised mt-4 md-success btn-fill"
              },
              buttonsStyling: false
            });
            this.reload();
          }
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    reload() {
      this.arquivosFromServ = [];
      this.arquivosFromPen = [];
      this.ActionSetARQUIVO("");
      this.ActionSetPASTA("");
      this.arquivos = [];
      this.pastas = [];

      if (this.tipoProprietario === "SERVIDOR") {
        this.getArquivoFromServ().then(() => {
          this.reloadServidorPensionista();
        });
      }
      if (this.tipoProprietario === "PENSIONISTA") {
        this.getArquivoFromPen().then(() => {
          this.reloadServidorPensionista();
        });
      }
    },
    reloadServidorPensionista() {
      if (this.arquivosFromServ.length > 0) {
        this.arquivosFromServ.map(e => {
          let arquivoJson = e;
          arquivoJson = {
            ...arquivoJson,
            idEntidade: this.servidorPublicoSelected.idEntidade
          };
          this.arquivos.push(Vue.util.extend({}, arquivoJson));
        });
        this.ActionSetARQUIVO(this.arquivos);
        let nomePasta = [];
        this.arquivos.map(e => {
          nomePasta.push(e.nomePasta);
        });
        let uniqueNomePasta;
        uniqueNomePasta = nomePasta.filter(function(item, pos) {
          return nomePasta.indexOf(item) == pos;
        });
        nomePasta = null;
        uniqueNomePasta.map((p, i) => {
          let pasta = this.pastaInicial();
          pasta.nomePasta = p;
          Object.keys(this.uploadedFiles).map(key => {
            if (i.toString() === this.uploadedFiles[key].index.toString()) {
              pasta.uploadedFiles = this.uploadedFiles[key];
            }
          });

          pasta.uploadedFiles.totalFiles > 0
            ? (pasta.currentStatus = STATUS_SUCCESS)
            : (pasta.currentStatus = STATUS_INITIAL);

          this.arquivos.map(file => {
            if (file.nomePasta === p) {
              pasta.idPasta = file.idPasta;
              pasta.arquivos.push(file);
            }
          });
          pasta.arquivos.sort(function(a, b) {
            return a.nomeArquivo < b.nomeArquivo
              ? -1
              : a.nomeArquivo > b.nomeArquivo
              ? 1
              : 0;
          });
          this.pastas.push(Vue.util.extend({}, pasta));
          this.ActionSetPASTA(this.pastas);
        });
        this.pastas.sort(function(a, b) {
          return a.nomePasta < b.nomePasta
            ? -1
            : a.nomePasta > b.nomePasta
            ? 1
            : 0;
        });
        this.uploadedFiles = [];
        this.callScrollToTopPage();
      } else if (this.arquivosFromPen.length > 0) {
        this.arquivosFromPen.map(e => {
          let arquivoJson = e;
          arquivoJson = {
            ...arquivoJson,
            idEntidade: this.pensionistaSelected.idEntidade
          };
          this.arquivos.push(Vue.util.extend({}, arquivoJson));
        });
        this.ActionSetARQUIVO(this.arquivos);
        let nomePasta = [];
        this.arquivos.map(e => {
          nomePasta.push(e.nomePasta);
        });
        let uniqueNomePasta;
        uniqueNomePasta = nomePasta.filter(function(item, pos) {
          return nomePasta.indexOf(item) == pos;
        });
        nomePasta = null;
        uniqueNomePasta.map((p, i) => {
          let pasta = this.pastaInicial();
          pasta.nomePasta = p;
          Object.keys(this.uploadedFiles).map(key => {
            if (i.toString() === this.uploadedFiles[key].index.toString()) {
              pasta.uploadedFiles = this.uploadedFiles[key];
            }
          });
          pasta.uploadedFiles.totalFiles > 0
            ? (pasta.currentStatus = STATUS_SUCCESS)
            : (pasta.currentStatus = STATUS_INITIAL);

          this.arquivos.map(file => {
            if (file.nomePasta === p) {
              pasta.idPasta = file.idPasta;
              pasta.arquivos.push(file);
            }
          });
          pasta.arquivos.sort(function(a, b) {
            return a.nomeArquivo < b.nomeArquivo
              ? -1
              : a.nomeArquivo > b.nomeArquivo
              ? 1
              : 0;
          });
          this.pastas.push(Vue.util.extend({}, pasta));
          this.ActionSetPASTA(this.pastas);
        });
        this.pastas.sort(function(a, b) {
          return a.nomePasta < b.nomePasta
            ? -1
            : a.nomePasta > b.nomePasta
            ? 1
            : 0;
        });
        this.uploadedFiles = [];
        this.callScrollToTopPage();
      } else {
        this.pastas = JSON.parse(JSON.stringify([this.pastaInicial()]));
      }
    },
    pastaInicial() {
      let pasta;
      return (pasta = {
        idPasta: "",
        nomePasta: "raiz",
        nomePastaAnterior: "",
        arquivos: [],
        showFieldPasta: false,
        uploadFieldName: "files",
        currentStatus: STATUS_INITIAL,
        uploadedFiles: {}
      });
    },
    checkEditionDisabled() {
      if (this.isDisabledEdition === true) {
        this.showDrag = false;
        if (
          typeof this.pastas[0] !== "undefined" &&
          this.pastas[0] !== null &&
          typeof this.pastas !== "undefined" &&
          this.pastas !== null &&
          typeof this.pastas[0].currentStatus !== "undefined" &&
          this.pastas[0].currentStatus !== null
        ) {
          this.fieldPastaChangeName(false, null);
        }
        this.showIconTrashUpdater = false;
        return "disabled";
      } else {
        this.showIconTrashUpdater = true;
        this.showDrag = true;
        return null;
      }
    },
    async loadForm() {
      if (this.arquivoState.length > 0 && this.pastaState.length > 0) {
        Object.keys(this.pastaState).map(key => {
          return this.pastas.push(Vue.util.extend({}, this.pastaState[key]));
        });
        Object.keys(this.arquivoState).map(key => {
          return this.arquivos.push(
            Vue.util.extend({}, this.arquivoState[key])
          );
        });
      } else {
        if (this.arquivosFromServ.length > 0) {
          this.arquivosFromServ.map(e => {
            let arquivoJson = e;
            arquivoJson = {
              ...arquivoJson,
              idEntidade: this.servidorPublicoSelected.idEntidade
            };
            this.arquivos.push(Vue.util.extend({}, arquivoJson));
          });
          this.ActionSetARQUIVO(this.arquivos);
          let nomePasta = [];
          this.arquivos.map(e => {
            nomePasta.push(e.nomePasta);
          });
          let uniqueNomePasta;
          uniqueNomePasta = nomePasta.filter(function(item, pos) {
            return nomePasta.indexOf(item) == pos;
          });
          nomePasta = null;
          uniqueNomePasta.map(p => {
            let pasta = this.pastaInicial();
            pasta.nomePasta = p;
            this.arquivos.map(file => {
              if (file.nomePasta === p) {
                pasta.idPasta = file.idPasta;
                pasta.arquivos.push(file);
              }
            });
            pasta.arquivos.sort(function(a, b) {
              return a.nomeArquivo < b.nomeArquivo
                ? -1
                : a.nomeArquivo > b.nomeArquivo
                ? 1
                : 0;
            });
            this.pastas.push(Vue.util.extend({}, pasta));
            this.ActionSetPASTA(this.pastas);
          });
          this.pastas.sort(function(a, b) {
            return a.nomePasta < b.nomePasta
              ? -1
              : a.nomePasta > b.nomePasta
              ? 1
              : 0;
          });
        } else if (this.arquivosFromPen.length > 0) {
          this.arquivosFromPen.map(e => {
            let arquivoJson = e;
            arquivoJson = {
              ...arquivoJson,
              idEntidade: this.pensionistaSelected.idEntidade
            };
            this.arquivos.push(Vue.util.extend({}, arquivoJson));
          });
          this.ActionSetARQUIVO(this.arquivos);
          let nomePasta = [];
          this.arquivos.map(e => {
            nomePasta.push(e.nomePasta);
          });
          let uniqueNomePasta;
          uniqueNomePasta = nomePasta.filter(function(item, pos) {
            return nomePasta.indexOf(item) == pos;
          });
          nomePasta = null;
          uniqueNomePasta.map(p => {
            let pasta = this.pastaInicial();
            pasta.nomePasta = p;
            this.arquivos.map(file => {
              if (file.nomePasta === p) {
                pasta.idPasta = file.idPasta;
                pasta.arquivos.push(file);
              }
            });
            pasta.arquivos.sort(function(a, b) {
              return a.nomeArquivo < b.nomeArquivo
                ? -1
                : a.nomeArquivo > b.nomeArquivo
                ? 1
                : 0;
            });
            this.pastas.push(Vue.util.extend({}, pasta));
            this.ActionSetPASTA(this.pastas);
          });
          this.pastas.sort(function(a, b) {
            return a.nomePasta < b.nomePasta
              ? -1
              : a.nomePasta > b.nomePasta
              ? 1
              : 0;
          });
        } else {
          this.pastas = JSON.parse(JSON.stringify([this.pastaInicial()]));
        }
      }
      this.isFetching = false;
      if (this.isEdition) {
        this.editionMode = true;
      }
    },
    async loadServidor() {
      if (
        this.servidorPublicoSelected.cpfServidor &&
        this.servidorPublicoSelected !== "" &&
        this.servidorPublicoSelected !== null
      ) {
        this.loadForm();
      } else {
        Swal.fire({
          title: "ATENÇÃO!",
          icon: "warning",
          text: "Por favor reinicie a partir do Cadastro !",
          customClass: {
            confirmButton: "md-button md-warning btn-fill"
          },
          buttonsStyling: false
        });
        window._Vue.$router.push({ name: "Cadastro" }).catch(err => {});
      }
    },
    async loadPensionista() {
      if (
        typeof this.pensionistaSelected !== "undefined" &&
        this.pensionistaSelected !== "" &&
        this.pensionistaSelected !== null &&
        this.pensionistaSelected.idPensionista
      ) {
        this.loadForm();
      } else {
        Swal.fire({
          title: "ATENÇÃO!",
          icon: "warning",
          text: "Por favor reinicie a partir do Cadastro !",
          customClass: {
            confirmButton: "md-button md-warning btn-fill"
          },
          buttonsStyling: false
        });
        window._Vue.$router
          .push({ name: "Cadastro Pensionista" })
          .catch(err => {});
      }
    },
    async getArquivoVersoesById(idArquivo) {
      try {
        this.versoesArquivos = [];
        let id = idArquivo;
        let papel = this.papel;
        const response = await this.ActionGetArquivoVersoesById({
          id,
          papel
        }).then(res => {
          let response = res;
          if (response.status === 200) {
            if (response.body.mensagem) {
              this.mensagem = response.body.mensagem;
              if (!this.mensagem.includes("não")) {
                Swal.fire({
                  icon: "error",
                  title: `ERROR - idArquivo: ${this.idServidor}`,
                  text: `${this.mensagem}`,
                  customClass: {
                    confirmButton:
                      "md-button md-raised mt-4 md-success btn-fill"
                  },
                  buttonsStyling: false
                });
              }
            } else {
              return (this.versoesArquivos = response.body);
            }
          }
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getArquivoFromServ() {
      try {
        let id = this.idServidor;
        let papel = this.papel;
        let idEntidade = this.user_profile_state.idEntidade;
        const response = await this.ActionGetArquivoFromServ({
          id,
          papel,
          idEntidade
        }).then(res => {
          let response = res;
          if (response.status === 200) {
            if (response.body.mensagem) {
              this.mensagem = response.body.mensagem;
              if (!this.mensagem.includes("não")) {
                Swal.fire({
                  icon: "error",
                  title: `ERROR - idServidor: ${this.idServidor}`,
                  text: `${this.mensagem}`,
                  customClass: {
                    confirmButton:
                      "md-button md-raised mt-4 md-success btn-fill"
                  },
                  buttonsStyling: false
                });
              }
            } else {
              return (this.arquivosFromServ = response.body);
            }
          }
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getArquivoFromPen() {
      try {
        let id = this.idPensionista;
        let papel = this.papel;
        let idEntidade = this.user_profile_state.idEntidade;
        const response = await this.ActionGetArquivoFromPen({
          id,
          papel,
          idEntidade
        }).then(res => {
          let response = res;
          if (response.status === 200) {
            if (response.body.mensagem) {
              this.mensagem = response.body.mensagem;
              if (!this.mensagem.includes("não")) {
                Swal.fire({
                  icon: "error",
                  title: `ERROR - idServidor: ${this.idServidor}`,
                  text: `${this.mensagem}`,
                  customClass: {
                    confirmButton:
                      "md-button md-raised mt-4 md-success btn-fill"
                  },
                  buttonsStyling: false
                });
              }
            } else {
              return (this.arquivosFromPen = response.body);
            }
          }
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async submit(index) {
      try {
        let nomePasta = this.pastas[index].nomePasta;
        nomePasta = { nomePasta };
        let id = this.pastas[index].idPasta;
        var papel = this.papel;
        this.ActionPUTPasta({
          id,
          papel,
          nomePasta
        }).then(response => {
          //console.log(response);
          if (response.status == 200 && response.body.total > 0) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: response.body.total + " arquivos atualizados.",
              showConfirmButton: false,
              timer: 1500,
              onOpen: toast => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            //console.log(response);
          }
          this.ActionSetPASTA(this.pastas);
        });
      } catch (err) {
        this.handleError(err);
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    reset() {
      // reset form to initial state
      if (this.pastas.length > 0) {
        this.pastas.map(p => {
          p.uploadedFiles = {};
          p.currentStatus = STATUS_INITIAL;
        });
      }
    },
    uploadVersao(
      fieldName,
      file,
      idArquivo,
      nomeArquivo,
      idPasta,
      index,
      versao
    ) {
      let totalFiles = 1;
      const formDataVersao = new FormData();
      // append the files to FormData
      formDataVersao.append(fieldName, file, file.name);
      formDataVersao.append("nomeArquivo", nomeArquivo);
      formDataVersao.append("idPasta", idPasta);
      formDataVersao.append("versao", versao + 1);
      formDataVersao.append("tipoProprietario", this.tipoProprietario);
      // save it
      this.pastas[index].currentStatus = STATUS_SAVING;
      uploadNovaVersao(axiosInstance, formDataVersao, idArquivo)
        .then(response => {
          if (response.mensagem.includes("sucesso")) {
            this.pastas[index].uploadedFiles = { totalFiles, index };
            this.uploadedFiles = [].concat({ totalFiles, index });
            this.pastas[index].currentStatus = STATUS_SUCCESS;
            Swal.fire({
              title: "Arquivo : Versionado.",
              icon: "success",
              text: response.mensagem,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false
            });
          } else if (response.mensagem.includes("não")) {
            Swal.fire({
              title: "Atenção!",
              icon: "warning",
              text: response.mensagem,
              customClass: {
                confirmButton: "md-button md-danger btn-fill"
              },
              buttonsStyling: false
            });
          }
          this.reload();
        })
        .catch(err => {
          this.handleError(err);
        });
    },
    filesChange(fieldName, fileList, nomePasta, idPasta, index) {
      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      if (idPasta === "") {
        let found = this.pastas.find(function(element) {
          return element.nomePasta === nomePasta && element.idPasta !== idPasta;
        });
        if (typeof found !== "undefined" && found !== null) {
          idPasta = found.idPasta;
        }
      }

      Array.from(Array(fileList.length).keys()).map(x => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });
      formData.append("tipoProprietario", this.tipoProprietario);
      formData.append("nomePasta", nomePasta);
      formData.append("idPasta", idPasta);
      formData.append("versao", 1);
      // save it
      this.save(formData, index, fileList.length);
    },
    save(formData, index, totalFiles) {
      // upload data to the server
      this.pastas[index].currentStatus = STATUS_SAVING;

      upload(axiosInstance, formData, this.id)
        .then(x => {
          if (x.mensagem.includes("sucesso")) {
            this.pastas[index].uploadedFiles = { totalFiles, index };
            this.uploadedFiles = [].concat({ totalFiles, index });
            this.pastas[index].currentStatus = STATUS_SUCCESS;
          }
          this.reload();
        })
        .catch(err => {
          this.handleError(err);
          //this.uploadError = err.response;
          //this.pastas[index].currentStatus = STATUS_FAILED;
        });
    },
    classicModalHide: function() {
      this.classicModal = false;
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  watch: {
    editionMode: function(val, oldVal) {
      let edition = val;
      if (edition) {
        this.isDisabledEdition = false;
        ///checkEditionDisabled();
      } else {
        this.isDisabledEdition = true;
      }
      this.emitToParent(edition);
    }
  }
};
</script>
<style scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

/*::v-deep .md-input {
  text-transform: lowercase;
}*/

.uppercase {
  text-transform: uppercase;
}

.switch-div {
  display: inline-block;
  float: right;
}

#container {
  display: flex; /* establish flex container */
  flex-direction: row; /* default value; can be omitted */
  flex-wrap: nowrap; /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  background-color: lightyellow;
}
#container > div {
  width: 100px;
  height: 100px;
  border: 2px dashed red;
}

.file.md-button.md-just-icon {
  height: 18px;
}

.nobull {
  list-style: none;
  padding-left: 20px;
}
.nobull li {
  position: relative;
  padding-left: 20px;
}
.nobull li:before {
  content: "";
  width: 10px;
  height: 15px;
  position: absolute;
  background-image: url("/img/file-icon.png");
  background-size: cover;
  background-position: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.box-list {
  width: 100%;
  height: 45px;
  padding: 15px 15px;
  background: rgb(255, 255, 255);
  cursor: pointer;
  vertical-align: middle;
}

.box-list:hover {
  background: rgb(212, 212, 212);
}

.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 80px;
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 80px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
}

.dropbox p {
  font-size: 0.9em;
  text-align: center;
  padding: 0px 0;
}

.grouping {
  display: inline-block;
  text-align: center;
}
.labelInLine {
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
}

.md-button.md-fab,
.md-button.md-just-icon {
  font-size: 24px;
  height: 30px;
  min-width: 30px;
  width: 30px;
  padding: 0;
  overflow: hidden;
  position: relative;
  line-height: 41px;
}

.folder.md-just-icon.md-simple {
  font-size: 35px;
  height: 35px;
  min-width: 35px;
  width: 35px;
  padding-right: 10px;
  overflow: hidden;
  position: relative;
  line-height: 35px;
  opacity: 1;
}

.folder.md-just-icon.md-simple i {
  font-size: 35px !important;
}

.folder.md-button i {
  width: 35px;
  min-width: 35px;
}
.list {
  width: 65%;
}
.md-list-item-container {
  font-size: 15px;
}
@media only screen and (max-width: 1024px) {
  .list {
    width: 100%;
  }
  .md-list-item-container {
    font-size: 11px;
    word-wrap: normal;
  }
  .box-list {
    white-space: pre-wrap;
  }
}

.list-modal {
  width: 80%;
}

.md-ripple > span {
  position: relative;
}

.md-list {
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}

::v-deep .modal-body.text-center {
  height: 350px;
  overflow-y: auto;
}
</style>

import * as axios from "axios";
import store from "../../../store";
import BASE_URL from "@/properties.js";

export const instance = axios.create({
  baseURL: BASE_URL
  //baseURL: "https://app.sindcalculos.com.br:8443/"
});
//const token = localStorage.getItem("token");
//export const instanceAuthorized = (instance.defaults.headers.common[
//"Authorization"
//] = `Bearer ${token}`);

function upload(axios, formData, id) {
  //const url = `${BASE_URL}/photos/upload`;
  return (
    axios
      .post(`/api/v1/admin/arquivo/upload/${id}`, formData)
      // get data
      .then(x => x.data)
    // add url field
    // .then(x =>
    //  x.map(img =>
    //Object.assign({}, img, { url: `${BASE_URL}/images/${img.id}` })
    //  )
    // )
  );
}

function uploadNovaVersao(axios, formData, id) {
  //const url = `${BASE_URL}/photos/upload`;
  return (
    axios
      .put(`/api/v1/admin/arquivo/versao/${id}`, formData)
      // get data
      .then(x => x.data)
  );
}

function download(axios, id, nome, versao, papel) {
  if (papel === "ADMIN") {
    axios({
      url: `/api/v1/admin/arquivo/download/${id}/${versao}`,
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.data.type })
      );
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      if (fileName !== "unknown") {
        link.setAttribute("download", fileName);
      } else {
        link.setAttribute("download", nome);
      }

      document.body.appendChild(link);
      link.click();
    });
  } else if (papel === "ADMIN_ENTIDADE") {
    axios({
      url: `/api/v1/admin_entidade/arquivo/download/${id}/${versao}`,
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.data.type })
      );
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      if (fileName !== "unknown") {
        link.setAttribute("download", fileName);
      } else {
        link.setAttribute("download", nome);
      }

      document.body.appendChild(link);
      link.click();
    });
  }
}

function downloadUser(axios, id, nome, versao, papel) {
  var fileNameReturn = null;
  let rsp = null;
  if (papel === "SERVIDOR") {
    rsp = axios({
      url: `/api/v1/user/servidorpublico/arquivo/download/${id}/${versao}`,
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.data.type })
      );
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      if (fileName !== "unknown") {
        link.setAttribute("download", fileName);
      } else {
        link.setAttribute("download", nome);
      }
      fileNameReturn = link.getAttribute("download");
      document.body.appendChild(link);
      link.click();
      return fileNameReturn;
    });
  }
  if (papel === "PENSIONISTA") {
    rsp = axios({
      url: `/api/v1/user/pensionista/arquivo/download/${id}/${versao}`,
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.data.type })
      );
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      if (fileName !== "unknown") {
        link.setAttribute("download", fileName);
      } else {
        link.setAttribute("download", nome);
      }
      fileNameReturn = link.getAttribute("download");
      document.body.appendChild(link);
      link.click();
      return fileNameReturn;
    });
  }
  return rsp;
}

export { upload, download, uploadNovaVersao, downloadUser };
